.main-navbar{
    background-color: #0f2239;
    width: 100%;
    position: fixed;
    z-index: 3;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    height: 5rem;
 
}
.main-navbar-nav{
    width: 90%;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.nav{
    height: 100%;
}

.navbar-nav:last-child{
    margin-right: auto;
}

.nav-group{
    gap: 1rem;
}



.nav:visited{
    cursor: pointer;
    color: inherit;
}

.half-width{
    width: 50%;
}
#logo{
    align-items: center;
    gap: .25rem;
}
#logo-img{
    height: 150%;
    width: auto;
}

#logo h3{
    font-size: 24px;
    font-weight: 300;
    font-family: "Lora", serif;
   
  
}
