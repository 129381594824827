#about #content{
    justify-content: space-between;
    text-align: left;
    position: relative;
    z-index: 1;
}

#about{
    background-image: url('../assets/bull.jpg');
    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

