:root{
    --navy-blue:#0f2239;
    --off-white: #E4EBF7;
}
html{
    scroll-behavior: smooth;
}
html,
body{
    margin: 0;
    padding: 0;
    font-family:Arial, Helvetica, sans-serif;
    color: white;
    
}
section{
    position: relative;
}

hr{
    width: 100%;;
}
a,
a::after,
a:visited{
    text-decoration: none;
    color: inherit;
}

.flex{
    display: flex;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.no-margin{
    margin: 0;
}

.bg-navy{
    background-color: var(--navy-blue);
    color: white;
}

.bg-white{
    background-color: var(--off-white);
    color: var(--navy-blue);
}

.center{
    margin: auto;
}
.align-center{
    align-items: center;
}
.text-center{
    text-align: center;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}



#content-divider{
width: 100%;
}

#content-divider span{
    border: solid white 2px;
    display: block;
    width: 20%;
}

/* Button CSS */
.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
.overlay{
    position: absolute;
    background: var(--transparent-blue);
    width: 100%;
    height: 100%;
    z-index: 1;
}

#content{
    width: min(1240px,90%);
    margin: auto;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.icon-card{
    width: 45%;   
    height: 100%;
}

#card{
    width: 100%;
}
#card-content .card-img{
    max-width:100%;
    max-height:100%;
    box-shadow: 30px -30px 0 var(--off-white);
}
p{
    line-height: 2em;
    font-size: 20px;
}

.flex hr{
    width: 0px;
}

#services{
    background-color: var(--off-white);
    color: var(--navy-blue);
}


#contact{
    background-color: var(--navy-blue);
    color: var(--off-white);

}


#stats{
    background-color: var(--off-white);
}
@media (max-width: 474px){
    #card-content p{
        font-size: 3.5dvw;
        
    }

}
@media (max-width: 1200px){


    #content{
        width: 90%;
        display: flex;
        flex-direction: column !important;
    }
    #card-content
    #content #industry-hr{
        display:  none;
    }
    
    article.active,
    article{
        height: 300px !important;
    }
    article.active h4{
        font-size: 2.5dvw !important;
    }

    h1{
        font-size: 2rem;
    }
    h1#action-text.header{
        font-size: 2rem;
    }
    p#action-text{
        font-size: 12px;
    }

    #icon-words svg{
        width: 10dvw;
    }
    #icon-words h3{
        font-size: 3dvw;
    }
    #card-content {
        width: 100%;
        justify-content: left;
    }
  
    #card-content h3{
        font-size: 1.5dvw;
    }
    #card-content h4{
        font-size: 2dvw;
    }
    #services-deck .icon-card #card-content h4{
        font-size: calc(.5vw + .5vh);
        text-wrap: nowrap;
       
    }

    #nav-links{
        display: none;
    }
    #burger-menu{
        display: block;
    }
    #card-content.contact svg,
    #card-content.contact h3{
        font-size: 1.75dvw;
    }
}