#experience #content{
    gap: 1rem;
}
.accordian{
    display: flex;
    gap: 5px;
    cursor: pointer;
    
}

article{
    position: relative;
    overflow: hidden;
    background-color: #FFFFFF;
    height: 300px;
    width: 30px;

    border: 1px solid;
    display: flex;
    align-items: flex-end;
    opacity: .9;
    transition: .5s ;
    
}
article.active{
    width: 300px;
    opacity: 1;
}
article.active h4{
    position: relative;
    display: block;
}
article.active img{
    filter: grayscale(0.5);
}

article h4{
    position: relative;
    color: white;
    left: 5px;
    font-size: 1.5rem;
    display: none;
    z-index: 1;
  
    flex-wrap: wrap;
}
article p{
    flex-wrap: wrap;
}

article img{
    position: absolute;
    z-index: 0;
    filter: grayscale(5);
    pointer-events: none;
    max-height: 100%;
    min-width: 100%;
}

article.active #overlay-2{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
   background:  linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
}

#blockquote{
   
    max-width: 600px;
  
    line-height: 1.25;
  
}
.blockquote__text{
    font-style: italic;
    font-size: 1.5em;
    margin: 0;
    
}
.blockquote--quoted::before{
    content: "\201C";
    font-size: 100px;
    color: white;
    display: block;
    height: 75px;
}
.blockquote--quoted::after{
    content: "\201D";
    font-size: 100px;
    color: white;
    float: inline-end;
    display: block;
    height:50px;
    position: relative;
    top: -25px;
}

.blockquote__text--credit::before{
    content: "\2014\0020";
}