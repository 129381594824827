:root{
    --transparent-blue:rgba(2, 7, 25, 0.718);
}



#banner{
    width: 100%;
    height: min(70dvh, 500px);
    position: relative;
    color: black;
    align-items: center;   
    margin-top: 5rem;
}

#banner video{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

#banner-content{
    width: min(90%, 1240px);
    color: white;
    z-index: 2;
    margin: auto;
    padding: 10px;
    text-align: left;
}


#action-text{
    font-family: "Lora", serif;
    font-weight:600;
    font-style: normal;
}

#action-text.button-6 a{
    color:black
}

h1#action-text{
    font-size: 3em;
}




#plain-banner{
    height: 20dvh;
    width: 100%;
}

#plain-banner img{
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}