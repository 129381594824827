#contact #content #content-divider span{
    border-color: var(--off-white);
}

#contact-info{
    gap: 1rem;
    width: auto;
}

#contact-card{
    width: 75%;
   
}
#contact-card #card-content{
    gap: 1rem;
    margin: 0;
    margin-left: 2rem;
}
#contact-card a{
    text-decoration: none;
    color: black;
}
#card-content.contact{
    display: flex;
    align-items: center;
}


#card-content svg{
    font-size: 1.25rem;
}

form{
    gap: 1.25em;
    border: solid 1px white;
    padding: 1em;

}
form div{
    gap: 1em;
    width: 100%;
}

input,
textarea,
button{
    font-size: 1.25em;
    background-color: #E4EBF7;
    box-sizing: border-box;
}
label{
    font-size: 1em;
}
#contact-form button{
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: .5em;
 
    gap: .25em;
   
}



textarea{
  resize: vertical;
  font-family:Arial, Helvetica, sans-serif;
 
}
@media (max-width: 1000px){
    input,
    textarea,
    button{
        width: 100%;
    }
}



/* CSS */
.button-59 {
  align-items: center;
  background-color: transparent;
  border: 2px solid #E4EBF7;
  box-sizing: border-box;
  color: #E4EBF7;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter,sans-serif;
  font-weight: 100;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
 
}



.button-59:hover {
  background-color: #E4EBF7;
  color: black;
  
}



@media (min-width: 768px) {
  .button-59 {
    min-width: 170px;
  }
}