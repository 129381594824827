@font-face {
  font-family: "CustomFont";
  src: url(./fonts/Dustismo_Roman.ttf);
}

h1{
  font-size: 2rem;
}

#logo-text{
  font-family: "CustomFont";
  font-weight: 200;
  font-size: 36px;
}